import React from 'react';
import Text from '../../Typography';
import Button from '../../Button';
import Icon from '../../Icon';
import Form from '../../Form';
import { Section, Wrapper, Container, Row } from '../styles/Contact.styled';

const Contact = ({ data }) => (
	<Section id={data.id}>
		<Wrapper>
			<Container>
				<Text Element="h5" text={data?.headingKicker} />
				<Text
					Element="h2"
					text={data?.heading}
					fontWieght="800"
					classes="mt-2 mb-4"
				/>
				<Text Element="div" html={data?.subhead} fontSize="1.125rem" />
			</Container>
			<Container>
				<Form data={data?.featuredContent} />
			</Container>
		</Wrapper>
	</Section>
);

export default Contact;
